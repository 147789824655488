<template lang="pug">
  

  
  .home(v-if="$store.state.currentOrg && $store.state.currentOrg != {} && $store.state.currentOrg._id")
   
    .row
        .col-12.col-md-4
            div(style="max-width: 600px")
                h6.mt-3
                Comments(:obj_id="$store.state.currentOrg._id", obj_selector="organization_id", variant="outline-secondary", headline="Notes and Insights")
                .mb-5
        .col-12.col-md-5
            div(style="max-width: 600px")
                h6.mt-3
                Contacts(:obj_id="$store.state.currentOrg._id", obj_selector="organization_id", variant="outline-secondary", ref="cc")
                .mb-5

        template(v-if="instance_config.contact_discovery_request")
            .col-12.col-md-3
            
                b Contacts of interests
                    .small (auto-discover)
                    .mt-3(v-if="no_contacts")
                        .small Could not find any contacts.


                div(v-if="auto_contacts")
                    div(style="max-height: 400px; height: 400px; overflow-y: auto", v-if="auto_contacts")

                        div(v-for="it in auto_contacts.data").mt-4 
                            b-link(@click="$refs.cc.toggle_add_mode(); $refs.cc.new_item.first_name = it.nameDetail.firstName; $refs.cc.new_item.last_name = it.nameDetail.lastName; $refs.cc.new_item.linkedin_url = it.linkedInUri; $refs.cc.new_item.position = it.employments.filter(x => x.isCurrent)[0].title") {{it.name}} 
                        
                            b-link(:href="'//'+it.linkedInUri" target="_blank", v-if="it.linkedInUri").ml-1 <i class="fab fa-linkedin"></i>
                            b-link(:href="'//'+it.origin" target="_blank", v-if="!it.linkedInUri").ml-1 <i class="fas fa-external-link-square-alt"></i>
                            .small(v-for="emp in it.employments.filter(x => x.isCurrent)", v-if="emp && emp.employer")
                                | {{emp.title}} - {{emp.employer.name}}
                                
                    
</template>

<script>
// @ is an alias to /src

//import Vue from 'vue'
import Config from '@/config'
import Comments from '@/components/Comments.vue'
import Contacts from '@/components/Contacts.vue'
import UseCaseInfo from '@/components/UseCaseInfo.vue'
import TaxonomyDisplay from '@/components/TaxonomyDisplay.vue'
import ProjectStageSelect from '@/components/ProjectStageSelect.vue'
import UseCaseSelect from '@/components/UseCaseSelect.vue'
import Logo from '@/components/Logo.vue'

import Vue from 'vue'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Profile',
  
  data: function () {
    return {
        add_to_use_case: false,
        selectedProjectStage: null,
        selectedUseCase: null,
        shrink: false,
        funding_rounds: null,
        auto_contacts: null,
        no_contacts: null
    }
  },

  mounted: function() {
    
    this.host = Config.config.base_host;
    this.getData();

  },

   computed: {

        ...mapFields([
            'currentOrg.comments',
            'currentOrg',
            'instance_config'
        ]),
  
  },

  methods: {

    getData() {
        
     if(this.instance_config.contact_discovery_request) {
       
        this.$store.dispatch('start_loading');
        Vue.http.get(`${Config.config.base_host}/organizations/${this.$route.params.org_id}/autodiscover_contacts`).then(res => {
                this.auto_contacts = res.body;  
                this.$store.dispatch("stop_loading");
        }, () => {
            this.no_contacts = true;
            this.auto_contacts = null;
            this.$store.dispatch("stop_loading");
        });

    }

    }
  },


  components: {
      Comments,
      Contacts,
      TaxonomyDisplay,
      UseCaseInfo,
      UseCaseSelect,
      ProjectStageSelect,
      Logo
  }
}

</script>
<style lang="scss" scoped>

 #gcontainer {
        height: 500px;
        width: 100%;
    }

.feed-image {
    height: 50px;
    width: 50px;
}
.table td {
    padding: 0.2em;
}
.item {
    .links {
        //display: none;
        a{
       
        }
    }
   
}
</style>