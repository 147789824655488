
<template lang="pug">
    .main() 
        template(v-if="darken_bg")
          div(class="modal-backdrop", style="z-index: 1025", @click="toggle_add_mode(); closeAll()")

        // Header
        .row
          .col-6
            h6 {{headline}}
          .col-6
            .float-right
              b-btn(variant="outline-secondary", v-if="!add_mode", @click="toggle_add_mode()", size="sm")#add-contact-btn + Add contact
        
        // Add Contact
        .row
            .col-12
                b-card(v-if="add_mode", header="Add contact", style="z-index: 1030").shadow
                    
                  b-form(@submit.prevent="add()")
                    .form-row
                      .col-12
                        b-form-group(label="Position*")
                          v-select(id="new-position-1", :options="position_options", v-model="new_item.position", placeholder="Choose or enter position",  :taggable="true", size="sm", required).small
                    
                    .form-row
                      .col-6
                        b-form-group(label="First name")
                          b-input(id="new-first_name-1", v-model="new_item.first_name", size="sm")
                      .col-6
                        b-form-group(label="Last name")
                          b-input(id="new-last_name-1", v-model="new_item.last_name", size="sm")
                    .form-row
                      .col-12 
                        a(variant="link", id="new-additional-link-1", href="javascript:void(0)", @click="new_item.more_infos = true", v-show="!new_item.more_infos").small Add additional contact information 
                    
                    .form-row(v-show="new_item.more_infos")
                      .col-12 
                        hr
                      .col-6
                        b-form-group(label="Email")
                          b-input(id="new-email-1", v-model="new_item.email", size="sm")
                      .col-6
                        b-form-group(label="Mobile")
                          b-input(id="new-mobile-1",v-model="new_item.mobile", size="sm")
                      
                      .col-6
                        b-form-group(label="LinkedIn")
                          b-input(id="new-linkedin_url-1",v-model="new_item.linkedin_url", size="sm")
                      
                      .col-6
                        b-form-group(label="Contact Source")
                          b-input(id="new-source-1", placeholder="e.g. referal name or link", v-model="new_item.source", size="sm").small
                        
                    hr
                    .form-row

                    b-form-group(label="Comment")
                      b-textarea(id="new-comment-1", v-model="new_item.text", placeholder="e.g. where do I know this person from?", size="sm").small
                    .float-right
                        b-input-group.mt-2
                                b-btn(id="new-cancel-1", variant="outline-secondary", @click="toggle_add_mode()", size="sm") Cancel
                                b-btn(type="submit" id="new-add-1", variant="outline-primary",  size="sm", :disabled="!new_item.position").ml-1 Add contact
        // Show contacts
        .row
            .col-12
                  i.text-muted.small(v-if="!entries") Click "Add Contact" to add a new contact!
                  div(v-for="item in entries").mb-5
                    b-card(no-body).mt-2
                        .p-3
                          template(v-if="!item.edit_mode") 
                              .float-right
                                b-dropdown(size="sm" variant="link" no-caret, right).comment-dd.option-dropdown
                                  template(v-slot:button-content)
                                    i.fas.fa-ellipsis-h.gray-info
                                  b-dropdown-item(size="sm" @click="onItemEditMode(item)") Edit contact
                                  b-dropdown-item(size="sm" @click="remove(item)") Delete contact
                                  
                              .media.mt-1
                                  //Avatar(:username="item.first_name + ' ' + item.last_name", :size="40").mr-3
                                  .media-body()
                                      h5(id="headline-click-1", @click="onItemEditMode(item)").p-0.m-0 {{item.first_name}} {{item.last_name}} 
                                      .text-muted(@click="onItemEditMode(item)") {{item.position}} 
                                      .mt-3
                                        .small.text-muted(v-if="item.email") <a :href="'mailto:'+item.email">{{ item.email }}</a>
                                        .small.text-muted(v-if="item.mobile") <a :href="'tel:'+item.mobile">{{ item.mobile }}</a>
                                        .small.text-muted(v-if="item.linkedin_url") <a :href="item.linkedin_url">{{ item.linkedin_url }}</a>
                                
                        b-card(v-if="item.edit_mode", style="z-index: 1030", header="Edit contact").shadow
                            
                          b-form(@submit.prevent="update(edit_item)")
                            .form-row
                              .col-12
                                b-form-group(label="Position*").small
                                  v-select(id="edit-position-1", :options="position_options", v-model="edit_item.position", placeholder="Choose", :taggable="true", required)
                            .form-row
                              .col-6
                                b-form-group(label="First name", size="sm")
                                  b-input(v-model="edit_item.first_name", size="sm")
                              .col-6
                                b-form-group(label="Last name")
                                  b-input(v-model="edit_item.last_name", size="sm")
                            .form-row
                              .col-12 
                                a(variant="link",  @click="edit_item.more_infos = true", v-show="!edit_item.more_infos").small Add additional contact information 
                            
                            .form-row(v-show="edit_item.more_infos")
                              .col-12 
                                hr
                              .col-6
                                b-form-group(label="Email")
                                  b-input(id="edit-email-1", v-model="edit_item.email", size="sm")
                              .col-6
                                b-form-group(label="Mobile")
                                  b-input(id="edit-mobile-1", v-model="edit_item.mobile", size="sm")
                              
                              .col-6
                                b-form-group(label="LinkedIn")
                                  b-input(id="edit-linkedin_url-1", v-model="edit_item.linkedin_url", size="sm")
                              
                              .col-6
                                b-form-group(label="Contact Source")
                                  b-input(id="edit-source-1", placeholder="e.g. referal name or link", v-model="edit_item.source", size="sm")
                                
                            .float-right
                              b-input-group.mt-1
                                b-btn(id="edit-form-cancel-1" variant="outline-secondary", @click="offItemEditMode(item)", size="sm") Cancel
                                b-btn(type="submit" id="edit-form-save-1" variant="outline-primary",  size="sm", :disabled="!edit_item.position").ml-1 Save
      
                        Comments(:obj_id="item._id", obj_selector="contact_id", variant="outline-secondary", headline="Comments", size="sm", :embed="true")
                
                        
                        
        
</template>

<script>
// @ is an alias to /src
import Config from '@/config'
import Vue from 'vue'
import Avatar from 'vue-avatar'
import User from '@/components/User.vue'
import Comments from '@/components/Comments.vue'

import ClickOutside from 'vue-click-outside/index.js'

export default {
  name: 'Contact',
  props: {
    "value":  {
      default: () => { return {} }
    },
    "obj_selector":  {
      default: ""
    },
    "obj_id":  {
      default: ""
    },
    "headline":  {
      default: "Contacts",
      type: String
    }


  },
  data: function () {
    return {
        position_options: ["CEO", "Manager", "Founder", "Co-Founder", "CTO", "CxO", "CSO", "Head of Sales", "Head of Marketing", "Director", "Co-Director"],
        uid: null,
        entries: null,
        add_mode: false,
        new_item: {"text": '', "more_infos": false},
        edit_item: null,
        darken_bg: false
    }
  },

  mounted: function() {
     this.uid = this._uid;
     this.getData();
  },


  
  methods: {

      closeAll() {
        this.add_mode = false;
        if(this.entries) this.entries.forEach((x)=>x.edit_mode=false);
      },

      toggle_add_mode() {
          
          this.new_item = {"text": '', "more_infos": false};
          this.add_mode = !this.add_mode;   
          this.darken_bg = this.add_mode;
      },

      add() {
        this.new_item[this.obj_selector] = this.obj_id;
        Vue.http.post(`${Config.config.base_host}/contacts`, this.new_item).then(() => {
           this.toggle_add_mode();
           this.entries = null;
           this.getData();
          this.$store.dispatch("loadOrg", {"org_id": this.$route.params.org_id});
           this.$forceUpdate();
        });
        
      },


      onItemEditMode(item) {
       
        item.edit_mode = true;
        
        this.edit_item = Object.assign({}, item);
        this.edit_item.more_infos = false;

        this.darken_bg = item.edit_mode;
      },
      offItemEditMode(item) {
        
        item.edit_mode = false;
        this.edit_item = null;
        this.darken_bg = item.edit_mode;
      },

      remove(item){
       
        this.$bvModal.msgBoxConfirm("Are you sure to delete this contact?", {buttonSize: 'sm'}).then((value) => {
          if(value) {
            item.deleted_at = true;
            this.update(item);
          }
        });
      },


      update(item) {
       
        Vue.http.put(`${Config.config.base_host}/contacts/${item._id}`, item).then(() => {
            
            this.offItemEditMode(item)
            this.getData();
        });
      },

      getData() {
        Vue.http.get(`${Config.config.base_host}/contacts?${escape(this.obj_selector)}=${escape(this.obj_id)}`).then(res => {
            
            res.body.forEach((x)=>{ x.edit_mode=false; x.more_infos = false; });
            
            this.entries = Object.assign({}, res.body);
            if(!Object.keys(this.entries).length) this.entries = null;
        });
      }

  },
  directives: {
    ClickOutside,
  },
  components: {
    Avatar,
    User,
    Comments
    
  }
}

</script>
<style lang="scss" scoped>
.feed-image {
    width: 50px;
    height: 50px;
}


</style>
