  <template lang="pug">
  
  .filter-group
  
    div(v-show="options.length > 0")
     
      template(v-for="topic_id in topic_ids", v-if="by_ids[topic_id]")
        b-link(:to="'/topics/usecases/'+topic_id", v-if="topic_type=='use_case'")
          b-badge(variant="light").mr-1 {{by_ids[topic_id].name}} 
        template(v-if="topic_type!='use_case'")
          b-badge(variant="light").mr-1 {{by_ids[topic_id].name}} 
            

    template(v-if="options.length == 0")
      .text-muted Loading ...

                 
</template>

<script>
// @ is an alias to /src
import Config from '@/config'
import Vue from 'vue'

export default {
  name: 'UserMultiSelect',
  props: {
    "topic_type": {
        default: null
    },
    "topic_ids": {
      type: Array,
      default: () => {return []}
    },
    "show_type": {
        type: Boolean,
       default: false
    },
    "value":  {
      default: null
    }, 
    "id":  {
      default: "UserMultiSelect"
    }, 
    "collpased": {
      type: Boolean,
      default: false
    },
  
    "closeOnSelect": {
      type: Boolean,
      default: true
    },
    "multiple": {
      type: Boolean,
      default: true
    },
    "disabled": {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
        uid: null,
        options: [],
        internal_value: null,
        by_ids: {}
    }
  },

  watch: {
        internal_value: function(val, oldVal) {
          console.log(val, oldVal);
          if(val != oldVal && oldVal) this.$emit("input", val);
        }
  },

  beforeMount: function() {
    this.internal_value = this.value;
  },

  mounted: function() {
     
     this.uid = this._uid;
     this.cleanSearch();
  },

  computed: {

  },

  methods: {
    change(event) {
      alert(event);
    },

    cleanSearch() {
        let vm = this;

        if(this.$store.state.topic_type_cache[this.topic_type]) {
          this.$store.state.topic_type_cache[this.topic_type].forEach((x) => {
            vm.by_ids[x._id] = x;
            vm.options.push(x);
          });
        } else {
            Vue.http.get(`${Config.config.base_host}/topics_v1?topic_type=${escape(this.topic_type)}`).then(res => {
              this.$store.state.topic_type_cache[this.topic_type] = res.body;
              res.body.forEach((x) => {
                vm.by_ids[x._id] = x;
                vm.options.push(x);
              });
        
          });

        }
      

    },
    


  },

  components: {
    
  }
}

</script>
<style lang="scss">

</style>
