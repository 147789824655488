
<template lang="pug">
    .main()
       
        template(v-if="darken_bg")
          div(class="modal-backdrop", style="z-index: 1025", @click="toggle_add_mode(); closeAll()")
        // Header
        .row(v-if="!embed")
          .col-6
            h6(:class="{'text-muted': size=='sm', 'small': size=='sm'}") {{headline}}
          .col-6
            .float-right
              span(:class="{'text-muted': size=='sm', 'small': size=='sm'}")
                b-btn(:variant="variant", v-if="!add_mode", @click="toggle_add_mode()", size="sm")#comment-add + Add note
                
        // Add note form
        .row
            .col-12
                b-card(v-if="add_mode", header="New note", style="z-index: 1030").shadow
              
                    b-textarea(v-model="new_note.text", placeholder="Add comment or company insight", size="sm")#new_note_text.mt-0
                    a(@click="" href="javascript:void(0);").small.text-muted.mt-2
                      
                      hr
                      FileAttachement(v-model="file") 
                    
                    .float-right
                        b-input-group.mt-2
                                b-btn(variant="outline-secondary", @click="toggle_add_mode()", size="sm")#comment-cancel-new Cancel
                                b-btn(variant="outline-primary", @click="add()", size="sm")#comment-add-new.ml-1 Add
        // Edit note form
        .row
            .col-12
                b-list-group
                    // If not entries
                    
                    .float-right
                        .text-muted.small(v-if="!entries") Click "Add Note" to add a new note or comment!
                    // If entries
                    .row(v-if="embed").mt-1
                     
                      .col-12
                        .float-right()
                            span(:class="{'text-muted': size=='sm', 'small': size=='sm'}")
                              b-btn(:variant="variant", v-if="!add_mode", @click="toggle_add_mode()", size="sm")#comment-add-embed + Add contact note
                    
                    b-list-group-item.card(v-for="(item, index) in entries").h-mouseover-parent.mt-1
                        
                        template(v-if="!item.edit_mode") 
                            .float-right.text-right
                              
                            
                              b-dropdown(size="sm" variant="link" no-caret, right).comment-dd.option-dropdown
                                template(v-slot:button-content)
                                  i.fas.fa-ellipsis-h.gray-info
                                b-dropdown-item(size="sm" @click="onItemEditMode(item)", :disabled="$store.state.user.id != item.owner_id") Edit comment
                                b-dropdown-item(size="sm" @click="remove(item)", :disabled="$store.state.user.id != item.owner_id") Delete comment
                
                              .text-muted.small() {{item.created_at | utcFrom}}
                
                            .media
                                User(:user="item.relations.owner_id", :size="25").mr-2
                                .media-body 
                                    .small(@click="onItemEditMode(item)", style="overflow-wrap: anywhere;").field_note {{item.text}} 
                                   
                                    a(v-if="item.relations.file_attachement_id" @click="open_file(item.relations.file_attachement_id._id, item.relations.file_attachement_id.filename, item.relations.file_attachement_id.content_type)" href="javascript:void(0);").small.text-muted {{item.relations.file_attachement_id.filename.substring(0,30)}}
                                
                        b-card(v-if="item.edit_mode", style="z-index: 1030", header="Edit comment").shadow
                            b-textarea(v-model="item.text", placeholder="Edit comment", size="sm")#text-edit-area
                            .mt-3
                            a(@click="openFile(insight._id, name)" href="javascript:void(0);").small.text-muted
                              // i(class="fas fa-paperclip")
                              // | Attach file
                            .float-right
                                b-input-group.mt-1
                                    b-btn(variant="outline-secondary", @click="offItemEditMode(item)", size="sm")#comment-edit-cancel Cancel
                                    b-btn(variant="outline-primary", @click="update(item)", size="sm")#comment-edit-save.ml-1 Save
                      
                  
</template>

<script>
// @ is an alias to /src
import Config from "@/config";
import Vue from "vue";
import Avatar from "vue-avatar";
import User from "@/components/User.vue";
import FileAttachement from "@/components/FileAttachement.vue";

import ClickOutside from "vue-click-outside/index.js";



export default {
  name: "Comments",
  props: {
    value: {
      default: () => {
        return {};
      }
    },
    obj_selector: {
      default: ""
    },
    obj_id: {
      default: ""
    },
    variant: {
      default: "outline-primary",
      type: String
    },
    headline: {
      default: "Comments",
      type: String
    },

     embed: {
      default: false,
      type: Boolean
    },

    size: {
      default: "md",
      type: String
    }
  },
  data: function() {
    return {
      file: null,
      uid: null,
      entries: null,
      add_mode: false,
      new_note: { text: "" },
      darken_bg: false
    };
  },

  mounted: function() {
    this.uid = this._uid;
    this.getData();
  },

  watch: {
    value: {
      handler: function() {
        this.getData();
      },
      deep: true
    }
  },

  methods: {
    closeAll() {
      this.add_mode = false;
      this.entries.forEach(x => (x.edit_mode = false));
    },

    toggle_add_mode() {
      this.new_note = { text: "" };
      this.add_mode = !this.add_mode;
      this.darken_bg = this.add_mode;
    },

    add() {
      this.new_note[this.obj_selector] = this.obj_id;
      if(this.file) this.new_note.file_attachement_id = this.file._id;
      Vue.http
        .post(`${Config.config.base_host}/comments`, this.new_note)
        .then(() => {
          this.getData();

          this.toggle_add_mode();
          this.$forceUpdate();
          this.$store.dispatch("loadOrg", {
            org_id: this.$route.params.org_id
          });
        });
    },

    onItemEditMode(item) {
      if (this.$store.state.user.id != item.owner_id) return;
      item.edit_mode = true;
      this.darken_bg = item.edit_mode;
    },

    offItemEditMode(item) {
      item.edit_mode = false;
      this.darken_bg = item.edit_mode;
    },

    remove(item){
        this.$bvModal.msgBoxConfirm("Are you sure to delete this comment?", {buttonSize: 'sm'}).then((value) => {
          if(value) {
            item.deleted_at = true;
            this.update(item);
          }
        });
      
    },

    update(item) {
      if(this.file) this.item.file_attachement_id = this.file._id;
      Vue.http
        .put(`${Config.config.base_host}/comments/${item._id}`, item)
        .then(() => {
          this.getData();
          this.offItemEditMode(item);

          this.$forceUpdate();
        });
    },

    getData() {
      Vue.http
        .get(
          `${Config.config.base_host}/comments?${escape(
            this.obj_selector
          )}=${escape(this.obj_id)}`
        )
        .then(res => {
          res.body.forEach(x => (x.edit_mode = false));

          this.entries = Object.assign({}, res.body);
          if (!Object.keys(this.entries).length) this.entries = null;
          this.$forceUpdate();
        });
    },
    open_file(id, name, mimetype) {

    var saveAsFile = function(fileName, fileContents, mimetype) {
      if (typeof(Blob) != 'undefined') { // Alternative 1: using Blob
          var textFileAsBlob = new Blob([fileContents], {type: mimetype});
          var downloadLink = document.createElement("a");
          downloadLink.download = fileName;
          if (window.webkitURL != null) {
              downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
          } else {
              downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
              //downloadLink.onclick = document.body.removeChild(event.target);
              downloadLink.style.display = "none";
              document.body.appendChild(downloadLink);
          }
          downloadLink.click();
      } else { // Alternative 2: using Data
          var pp = document.createElement('a');
          pp.setAttribute('href', 'data:'+mimetype+';charset=utf-8,' +
              encodeURIComponent(fileContents));
          pp.setAttribute('download', fileName);
          //pp.onclick = document.body.removeChild(event.target);
          pp.click();
      }
  }


    this.$store.dispatch("start_loading");
    return Vue.http.get(Config.config.base_host+'/files/'+id+'/file.pdf', {responseType: 'arraybuffer'}).then((response) => {
      
      // function base64ToUint8Array(b64) {
      //   var raw = atob(b64);
      //   var uint8Array = new Uint8Array(raw.length);
      //   for (var i = 0; i < raw.length; i++) {
      //     uint8Array[i] = raw.charCodeAt(i);
      //   }
      //   return uint8Array;
      // }

      console.log(response);
      
      saveAsFile(name, response.body, mimetype)
       this.$store.dispatch("stop_loading");

      /*
      console.log(response.body)
      window.open("data:application/pdf," + escape(response.body.data)); 
      let blob = new Blob([base64ToUint8Array(response.body.data)], { type: response.body.mimetype } ),
      url = window.URL.createObjectURL(blob)
      window.open(url, '_blank');
      */
    });
  }

  },
  directives: {
    ClickOutside
  },
  components: {
    Avatar,
    User,
    FileAttachement,
  }
};
</script>
<style lang="scss" scoped>
.feed-image {
  width: 50px;
  height: 50px;
}
</style>
