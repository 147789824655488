
<template lang="pug">
    .main()
       
        vue-dropzone(id="drop1" :options="dropOptions", @vdropzone-complete="complete")
          
                   
                        
</template>

<script>
// @ is an alias to /src
import Config from "@/config";
import Vue from "vue";
import ClickOutside from "vue-click-outside/index.js";
import vueDropzone from "vue2-dropzone"
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: "FileAttachement",
  props: {
    value: {
      default: () => {
        return [];
      }
    }
  },
  
  data: function() {
    return {
        files: [],
        is_complete: false,
        file: null,
        dropOptions: {
          dictDefaultMessage: "<i class='fas fa-paperclip'></i> Add file",
          url: Config.config.base_host + "/files",
          maxFilesize: 200, // MB
        
          //headers: {'Authorization': 'Bearer ' + localStorage.getItem('id_token')},
          maxFiles: 1,
          thumbnailWidth: 0, // px
          thumbnailHeight: 0  ,
          addRemoveLinks: true
        }

    }
  },

  mounted: function() {
    this.uid = this._uid;
    this.files = JSON.parse(JSON.stringify(this.value));
    this.post_action = Config.config.base_host + "/files";
    this.dropOptions.headers = Vue.http.headers.common;
  },


  watch: {
    'file': function(val) {
  
        this.$emit("input", val);
    }
  },

  methods: {

    complete(e) {
      
      if(e.xhr.status == 429) {
         this.$bvToast.toast("Invalid or malicious file", {
            title: 'Upload failed',
            variant: 'danger',
            autoHideDelay: 10000,
          });
          return;
      }
      this.is_complete = true;
      console.log(e.xhr.responseText);
      this.file = JSON.parse(e.xhr.responseText)[0];
    }

  },
  directives: {
    ClickOutside
  },
  components: {
   vueDropzone
  }
};
</script>
<style lang="scss">

@import '~@/_vars.scss';


.dropzone, .dropzone:hover {
    background-color: transparent;
    margin: 0px;
    letter-spacing: auto;
    color: $gray-default;
    transition: background-color .2s linear;
    max-width: 200px;
    padding: 5px;
    min-height: 30px;
    border: 1px solid $gray-default;
    .dz-message {
      margin: 0px;
    }
  }

.vue-dropzone .dz-preview .dz-remove {
  
}

.dropzone.dz-started .dz-message {
  display: inline-block  !important;
  text-align: center !important;
  width:100% !important;
}

.vue-dropzone .dz-preview .dz-details {
    margin-top: 5px;
    color: $gray-default;
    margin-top: 0px;
    background-color: white;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    text-align: left;
}


.dropzone .dz-preview .dz-details .dz-size {
  font-size: 11px;
  margin-bottom: 0px;
  display: none;
}


.dropzone .dz-image {
  display:none !important,
}

.dropzone .dz-preview .dz-details {
    z-index: 20;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 11px;
    min-width: 100%;
    max-width: 100%;
    padding: $pad;
    text-align: center;
}

.dropzone .dz-preview {
  margin: 0;

}
.dropzone .dz-preview .dz-details {
    z-index: 20;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 11px !important;
  
    padding: 0px;
    text-align: center;
}
.vue-dropzone .dz-preview .dz-remove {
  color: $gray-default;
  position: relative;
  background-color: $gray-default;
  color: $light;
}


  .dropzone .dz-preview {
    width: auto;
    display: inline-block;
    min-height: auto;
    width: 100%;
  }

 .dropzone .dz-preview .dz-image {
    width: 80px;
    height: 80px;
    margin-left: 40px;
    margin-bottom: 10px;
  }
  #customdropzone .dz-preview .dz-image > div {
    width: inherit;
    height: inherit;
    border-radius: 50%;
    background-size: contain;
  }
  #customdropzone .dz-preview .dz-image > img {
    width: 100%;
  }

   #customdropzone .dz-preview .dz-details {
    color: white;
    transition: opacity .2s linear;
    text-align: center;
  }
  #customdropzone .dz-success-mark, .dz-error-mark, .dz-remove {
    display: none;
  }

</style>
